import firstImage from '../image/innovation.jpeg'
import secondImage from "../image/investing.jpeg"
import thirdImage from "../image/employee.jpeg"
import fourthImage from "../image/rethink.jpeg"
import fifthImage from "../image/hybrid.jpeg"
const contentData = {
  name: "John Deo.",
  name2: "WOLF İNTERNET HİZMETLERİ",
  name3: "Alexa Johnson",
  role: "WOLF İNTERNET HİZMETLERİ",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "http://www.linkedin.com/",
      className: "social_facebook",
    },
    {
      name: "twitter",
      url: "http://www.twitter.com/",
      className: "social_twitter",
    },
    {
      name: "googleplus",
      url: "http://www.googleplus.com",
      className: "social_googleplus",
    },
    {
      name: "pinterest",
      url: "http://www.pinterest.com",
      className: "social_pinterest",
    },
  ],
  aboutme: "Hakkımızda",
  aboutdetails:
    `Wolf İnternet Hizmetleri olarak, bilgi teknolojileri sektöründe yenilikçi ve etkili çözümler sunarak, işletmelerin dijital dönüşüm süreçlerini hızlandırmayı ve rekabet güçlerini artırmayı hedefliyoruz. Yazılım geliştirme, IT danışmanlığı, siber güvenlik ve bulut bilişim hizmetleri gibi birçok alanda kapsamlı çözümler sunan deneyimli mühendislerimiz ve danışmanlarımızla müşteri memnuniyeti odaklı yaklaşımımızı birleştiriyoruz. Teknoloji dünyasında güvenilir bir ortak olarak tanınmak ve müşterilerimize sürdürülebilir başarılar getiren çözümler sunmak vizyonumuz doğrultusunda, Ar-Ge çalışmalarına ve yenilikçi projelere büyük önem veriyoruz.`,
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "İnovasyon",
      img: firstImage,
      sDetails:
        "Ağ izlemeyi otomatikleştirerek yenilik için daha fazla zaman yaratır.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Yatırım",
      img: secondImage,
      sDetails:
        "Wolf İnternet Hizmetleri, küresel piyasada müşterilerine birinci sınıf yatırım hizmetleri sunmaktadır. Bu platformda, hisse senetleri, endeksler, emtialar ve çeşitli finansal araçlar dahil olmak üzere geniş bir varlık yelpazesine erişim sağlanabilir.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Çalışanlarınızın deneyimini iyileştirin",
      img: thirdImage,
      sDetails:
        "Uzaktan çalışanlar ve ofiste veya şubede çalışanlar için esnek, işbirlikçi ve güvenilir bir ortam sağlıyoruz.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Organizasyonu yeniden düşünün",
      img: fourthImage,
      sDetails:
        "COVID-19, organizasyon yapıları ve yetenekler hakkında önceden var olan sorulara cevaplar sağladı. Uzaktan çalışma etkinliği ve esnek ekiplerin üretkenliği kanıtlanmış ve şimdi küresel ölçekte geniş bir şekilde benimseniyor.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Hibrit çalışma web semineri",
      img: fifthImage,
      sDetails:
        "Finans hizmetleri profesyonellerinden hibrit çalışma geçişine dair içgörüler edinin.",
    },
    // {
    //   id: 6,
    //   iconName: "icon-tools",
    //   serviceTitle: "We’re experienced",
    //   sDetails:
    //     "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    // },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: 107 Leadenhall St, London EC3A 4AF, United Kingdom",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +447534439562",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: support@wolfinternethizmetleri.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: #",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "İletişim Formu",
};

export default contentData;
