import React, { Component } from "react";
import Sticky from "react-stickynode";
import { Link } from "react-scroll";
class Navbar extends Component {
    render() {
        var { mClass, mContainer, mainlogo, stickylogo } = this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
                    <div style={{ flexWrap: 'nowrap' }} className={`container ${mContainer}`}>
                        <a className="navbar-brand logo_h" href="./">
                            <img src={require("../../image/wolf-logo.png")} alt="logo" width="100px" />
                            <img src={require("../../image/wolf-logo.png")} alt="logo" width="100px" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            <span className="navbar-toggler-icon"></span>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className="collapse navbar-collapse offset"
                            id="navbarSupportedContent"
                        >
                            <ul className="nav navbar-nav ms-3">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="home"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                    >
                                        Ana Sayfa
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                    >
                                        Hakkımızda
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="service"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                    >
                                        Hİzmetlerİmİz
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="testimonial"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                    >
                                        Neden Wolf İnternet Hİzmetlerİ ?
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-86}
                                        duration={500}
                                    >
                                        İletİşİm
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </Sticky>
            // <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            //     <nav className="navbar navbar-expand-lg bg-light">
            //         <div className="container">
            //             <a className="navbar-brand" href="#">Navbar</a>
            //             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            //                 <span className="navbar-toggler-icon" />
            //             </button>
            //             <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            //                 <div className="navbar-nav">
            //                     <a className="nav-link active" aria-current="page" href="#">Home</a>
            //                     <a className="nav-link" href="#">Features</a>
            //                     <a className="nav-link" href="#">Pricing</a>
            //                     <a className="nav-link disabled">Disabled</a>
            //                 </div>
            //             </div>
            //         </div>
            //     </nav>
            // </Sticky>
        );
    }
}

export default Navbar;
