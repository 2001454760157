import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Slider from 'react-slick';

class TestimonialSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: false,
      autoplay: false,
      pauseOnHover: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings} className="testimonial_slider">
          <div className="item">
            <div className='mb-4'>
              <img className='m-auto' src={require('../../image/wolf-logo-disi.png')} alt="logo" width="125px" />
            </div>
            <h3>Neden Wolf İnternet Hizmetleri ?</h3>
            {/* <span>Envato Customer</span> */}
            <p>Wolf İnternet Hizmetleri, bilgi teknolojileri alanında deneyimli kadrosu ve yenilikçi çözümleriyle dijital dönüşümünüzü hızlandırır. Müşteri odaklı yaklaşımımız ve sürekli gelişime verdiğimiz önemle, her zaman en yüksek kaliteyi sunmayı hedefliyoruz. Ar-Ge yatırımlarımız sayesinde, en son teknolojiyi kullanarak sürdürülebilir başarılar elde etmenize yardımcı oluyoruz.</p>
            <Link to="contact" className="theme_btn active">
              İletişim
            </Link>
          </div>

        </Slider>
      </div>
    );
  }
}
export default TestimonialSlider;