import React, { Component } from "react";
import { Parallax } from "react-parallax";
import { Fade } from "react-awesome-reveal";
import Typer from "./Typer";
import { Link } from "react-scroll";

class TypeBanner extends Component {
  render() {
    let contentData = this.props.contentData;
    return (
      <section id="home">
        <Parallax
          bgImage={require("../../image/stock-photo-double-exposure-of-graph-and-rows-of-coins-for-finance-and-business-concept-530884738-transformed.jpeg")}
          strength={0}
          className="banner_area"
        >
          <div className="container">
            <div className="banner_content">
              <Fade direction="up">
                {/* <h5 className="wow fadeInUp animated">HELLO</h5> */}
              </Fade>
              <Fade direction="up" duration={1500}>
                <h2 className="wow fadeInLeft animated">{contentData.name2}</h2>
              </Fade>
              <Fade direction="up" duration={2000}>
                {" "}
                <Typer
                  // heading={"More than"}
                  dataText={[
                    "Yatırım Kurumundan Daha Fazlası..."
                  ]}
                />
              </Fade>
              <div>
                <p>
                  Wolf İnternet Hizmetleri, küresel sermaye piyasalarında köklü bir geçmişe sahip, iyi kurulmuş bir finansal kuruluştur. Yıllar içinde uluslararası finans çevrelerinde güvenilir bir oyuncu olarak konumumuzu sağlamlaştırdık. Temel uzmanlık alanımız, özel kaldıraçlı ticaret ve vadeli işlem işlemlerini kapsayan kapsamlı hisse senedi aracılık hizmetleri sunmaktır.
                </p>
              </div>
              <Link to="service" className="theme_btn">
                Hizmetlerimiz
              </Link>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

export default TypeBanner;
