import React from 'react';
import TestimonialSlider from './TestimonialSlider';
import { Parallax} from 'react-parallax';

const Testimonial = () => {
  return(
      <section id="testimonial" className='testimonial-background'>
        <Parallax bgImage={require('../../image/stock-photo-gold-trade-chart-stock-analysis-on-growth-financial-business-money-market-d-background-with-profit-2220864059-transformed.jpeg')} strength={280} className="testimonial_area">
        <div className="container">
            <TestimonialSlider/>
        </div>
        </Parallax>
     </section>
  )
}
export default Testimonial;