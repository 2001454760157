import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
class Footer extends Component {
  render() {
    library.add(faEnvelope);
    library.add(faLocationDot);
    library.add(faPhone);
    let contentData = this.props.contentData;
    const emailAddress = 'support@wolfinternethizmetleri.com'
    // console.log(contentData.contact);
    return (
      <section className="footer-area">
        <div className="container">
          <div className="row">
            <div className="footer-content col-lg-3">
              <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
                <img className="footer-logo" src={require("../image/wolf-logo-disi.png")} alt="logo" />
              </Link>
            </div>
            <div className="footer-content col-lg-5">
            </div>
            <div className="col-lg-4 footer-icons">
              <p>İletişim</p>
              <p><FontAwesomeIcon className="icons" icon="fa-solid fa-envelope" />E-posta Adresi: <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>

            </div>
          </div>
        </div>
        <div className="mt-4 py-3" style={{ backgroundColor: "#0f1739" }}>
          <div className="container">
            <div className=" d-flex align-items-center justify-content-between flex-wrap">
              <div className="all-rights-reserved">
                <h6 className="mb-0" style={{ fontSize: "14px" }}>
                  Wolf İnternet Hizmetleri tarafından sahip olunmakta ve işletilmektedir.
                </h6>
              </div>
              <div className="all-rights-reserved">
                <p className="mb-0" style={{ fontSize: "14px" }}>© 2024 - Tüm Hakları Saklıdır - Avax Bilgisayar Yazılım Hizmetleri Danışmanlık Tic. Ltd. Şti.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Footer;
