import React, { Component } from "react";
import { Fade } from "react-awesome-reveal";
import Form from "./Form";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
class Contact extends Component {
  render() {
    let contentData = this.props.contentData;
    library.add(faEnvelope);
    library.add(faLocationDot);
    library.add(faPhone);
    const emailAddress = 'support@wolfinternethizmetleri.com'
    return (
      <section className="contact-area" id="contact">
        <div className="container">
          <div className="row row-reverse">
            <div className="col-md-5 contact-info">
              <p className="contact-p">İletişim</p>
              <p><FontAwesomeIcon className="icons" icon="fa-solid fa-envelope" />E-posta Adresi: <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>

            </div>

            <div className="col-md-7">
              <Fade direction="Right" triggerOnce duration={800}>
                <div className="input_form">
                  <h4>{contentData.contacttitle}</h4>
                  <Form />
                  <div id="success">Mesajınız başarıyla iletilmiştir!</div>
                  <div id="error">
                    Bir hata oluştu. Lütfen tekrar deneyiniz.
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;
