
import React from 'react';
import Navbar from './component/Navbar/Navbar';
import TypeBanner from './component/Banner/TypeBanner';
import About from './component/About';
import Service from './component/Service';
// import Portfolio from './component/Portfolio/Portfolio';
import Testimonial from './component/Testimonial/Testimonial';
// import Clientslogo from './component/Clientslogo';
// import Blog from './component/Blog/Blog';
// import MapContainer from './component/Map';
import Contact from './component/Contact';
// import Skill from './component/Skill/Skill';
import Footer from './component/Footer';
import contentData from './component/contentData';


const Home2 = () => {
  return <div className="body_wrapper">
    <Navbar mContainer="custome_container" mainlogo="wolf-logo.png" stickylogo="wolf-logo.png" />
    <TypeBanner contentData={contentData} />
    <About aClass="about_area" contentData={contentData} />
    <Service wClass="work_area" contentData={contentData} />
    {/* <Portfolio/> */}
    {/* <Skill/> */}
    <Testimonial />
    {/* <Clientslogo/> */}
    {/* <Blog/> */}
    {/* <MapContainer/> */}
    <Contact contentData={contentData} />
    <Footer contentData={contentData} />
  </div>;
};

export default Home2;

